import { getSluggableEntityName, getSluggableEntitySlug } from '../../helpers/sluggableEntity';

export default {
  methods: {
    getSluggableEntityName(arr) {
      const locale = this.spflocale;
      return getSluggableEntityName(arr, locale);
    },
    getSluggableEntitySlug(arr) {
      const locale = this.spflocale;
      return getSluggableEntitySlug(arr, locale);
    },
  },
};
