<template>
  <div class="navbar-dark">
    <b-sidebar
      id="sidebar-nav"
      right
      lazy
      backdrop
      width="430px"
      header-class="text-right py-0 px-3"
      bg-variant="white"
      shadow
      class="navbar__sidebar show"
    >
      <template #header="{ hide }">
        <span
          class="d-flex align-items-center justify-content-end font-weight-bolder pt-2 pb-3 w-100"
        >
          <span
            class="ml-2 cursor-pointer"
            @click="hide"
          >
            <i class="fa fa-times fa-xl" />
          </span>
        </span>
      </template>

      <template #default="{ hide }">
        <div
          v-if="spflocale"
          class="pt-3 pb-5 px-3"
        >
          <ul class="navbar-nav pt-2">
            <li v-if="center">
              <div class="pb-3 font-weight-bolder">
                <a
                  class="text-dark"
                  :href="`${Routing.generate('spartan.website.center_show', { slug, _locale: spflocale })}`"
                >
                  {{ center.name }}
                </a>
              </div>

              <span class="d-block pl-1">
                <a
                  class="nav-link"
                  :href="`${Routing.generate('spartan.website.center_show', { slug, _locale: spflocale })}`"
                  @click="hide"
                >
                  <span
                    class="d-inline-block"
                    style="width: 22px; text-align: center"
                    ><i class="far fa-home text-dark"
                  /></span>
                  {{ capitalize(trans('general.informations')) }}
                </a>
                <a
                  v-for="item in activityLinks"
                  :key="item.url"
                  class="nav-link"
                  :href="item.url"
                  @click="hide"
                >
                  <span
                    class="d-inline-block"
                    style="width: 22px; text-align: center"
                    ><i
                      class="fa-flag-pennant text-dark"
                      :class="item.instantBooking ? 'fa' : 'far'"
                  /></span>
                  {{ item.name }}
                </a>

                <a
                  class="nav-link"
                  :href="`${Routing.generate('spartan.website.center_show', { slug, _locale: spflocale })}#matches`"
                  @click="hide"
                >
                  <span
                    class="d-inline-block mr-1"
                    style="width: 22px; text-align: center"
                  >
                    <i class="far fa-ranking-star text-dark" />
                  </span>
                  {{ capitalize(trans('general.matches')) }}
                  <span
                    v-if="centerMatches"
                    class="badge badge-secondary badge-pill"
                  >
                    {{ centerMatches }}
                  </span>
                </a>
                <!-- <a
              class="nav-link"
              :href="`${Routing.generate('spartan.website.center_show',
                                         { slug })}#description`"
              onclick="$('#header-nav-menu').collapse('hide')"
            >{{ 'Horaires' }}</a> -->
                <!-- <a class="nav-link" href="{{ path('center_show') }}">{{ 'Messagerie' }}</a> -->

                <a
                  v-if="center.isPartner"
                  class="nav-link"
                  :href="`${Routing.generate('spartan.center.share_data', { slug, _locale: spflocale })}`"
                  @click="hide"
                >
                  <span
                    class="d-inline-block"
                    style="width: 22px; text-align: center"
                    ><i class="far fa-id-badge text-dark"
                  /></span>
                  {{ capitalize(trans('general.share_data')) }}
                </a>
              </span>

              <hr />
            </li>

            <li v-if="isLoggedIn">
              <div class="font-weight-bolder pt-2 pb-3">
                <a
                  class="text-dark"
                  :href="`${Routing.generate('member.favorite_centers', { _locale: spflocale })}`"
                >
                  {{ capitalize(trans('general.favorites')) }}
                </a>
              </div>
              <div class="pl-1 d-flex flex-column">
                <template v-if="favorites && favorites.length">
                  <a
                    v-for="item of favorites"
                    :key="item.id"
                    class="nav-link"
                    :href="item.url"
                  >
                    <span
                      class="d-inline-block"
                      style="width: 22px; text-align: center"
                    >
                      <i class="far fa-star" />
                    </span>
                    {{ item.name }}
                  </a>
                </template>
                <a
                  v-else
                  class="nav-link"
                  :href="`${Routing.generate('member.favorite_centers', { _locale: spflocale })}`"
                >
                  <span
                    class="d-inline-block"
                    style="width: 22px; text-align: center"
                  >
                    <i class="far fa-star" />
                  </span>
                  {{ capitalize(trans('nav.addFavorite')) }}
                </a>
              </div>
              <hr />
            </li>

            <li v-if="isLoggedIn">
              <div class="font-weight-bolder pt-2 pb-3">
                <a
                  class="text-dark"
                  href="/app"
                >
                  {{ capitalize(trans('nav.myAccount')) }}
                </a>
              </div>
              <div class="pl-1 d-flex flex-column">
                <div>
                  <a
                    :href="`${Routing.generate('member.purchases', { _locale: spflocale })}`"
                    class="nav-link"
                  >
                    <span
                      class="d-inline-block"
                      style="width: 22px; text-align: center"
                    >
                      <i class="far fa-wallet text-dark" />
                    </span>
                    {{ capitalize(trans('general.bookings')) }}

                    <i
                      v-if="bookingInvitations"
                      :title="`${bookingInvitations} invitations`"
                      class="far text-info fa-beat-fade"
                      :class="`fa-circle-${bookingInvitations}`"
                    />

                    <i
                      v-if="upcomingBookings"
                      class="far text-success"
                      :class="`fa-circle-${upcomingBookings}`"
                    />

                    <i
                      v-if="pendingBookings"
                      class="far text-danger"
                      :class="`fa-circle-${pendingBookings}`"
                    />
                  </a>

                  <a
                    :href="Routing.generate('member.my_wallets', { _locale: spflocale })"
                    class="nav-link"
                  >
                    <span
                      class="d-inline-block"
                      style="width: 25px; text-align: center"
                    >
                      <i class="far fa-wallet text-dark" />
                    </span>
                    {{ capitalize(trans('nav.myWallets')) }}
                  </a>

                  <a
                    :href="Routing.generate('member.home', { _locale: spflocale })"
                    class="nav-link"
                  >
                    <span
                      class="d-inline-block"
                      style="width: 22px; text-align: center"
                    >
                      <i class="far fa-cog text-dark" />
                    </span>
                    {{ capitalize(trans('general.settings')) }}
                    <i
                      v-if="!!user && (!user.email_valid || !user.contact?.has_phone_number)"
                      class="far text-danger fa-triangle-exclamation"
                    />
                  </a>
                </div>
              </div>
              <hr />
            </li>

            <li>
              <div class="font-weight-bolder pt-2 pb-3">
                <a
                  class="text-dark"
                  href="/"
                >
                  SportFinder
                </a>
              </div>
              <div class="pl-1">
                <a
                  class="nav-link"
                  :href="
                    Routing.generate('spartan.website.map', {
                      item1: currentCountrySlug,
                      _locale: spflocale,
                    })
                  "
                >
                  <i class="far mr-1 fa-map-location text-dark" />
                  {{ capitalize(trans('nav.clubsList')) }}
                </a>

                <a
                  class="nav-link"
                  :href="
                    Routing.generate('spartan.website.matches', {
                      item1: currentCountrySlug,
                      _locale: spflocale,
                    })
                  "
                >
                  <i class="far mr-1 fa-court-sport text-dark" />
                  {{ capitalize(trans('nav.matchesList')) }}
                </a>

                <!-- <a
                  class="nav-link"
                  :href="
                    Routing.generate('app_website_onboarding', {
                      _locale: spflocale,
                      utm_content: 'navbar',
                    })
                  "
                > -->
                <!-- :href="Routing.generate('app_website_onboarding', { _locale: spflocale, utm_content: 'navbar' })" -->
                <!-- <i class="far mr-1 fa-calendar-plus text-dark" /> -->
                <!-- {{ capitalize(trans('nav.addYourClub')) }} -->
                <!-- </a> -->

                <a
                  v-if="!isContactHidden"
                  class="nav-link"
                  :href="
                    Routing.generate('spartan.website.contact', {
                      src: currentUrl,
                      center: center ? center.id : null,
                      _locale: spflocale,
                    })
                  "
                  rel="nofollow"
                >
                  <i class="far mr-1 fa-envelope" />
                  <span class="d-inline">
                    {{ capitalize(trans('website.navigation.contact')) }}
                  </span>
                </a>

                <a
                  :href="Routing.generate('website.terms.terms_of_service', { _locale: spflocale })"
                  class="nav-link"
                >
                  <i class="far mr-1 fa-shield text-dark" />
                  {{ capitalize(trans('booking.payment.conditions')) }}
                </a>
              </div>
            </li>

            <li v-if="isAdmin">
              <hr />

              <div class="font-weight-bolder pt-2 pb-3">Administration</div>
              <div class="pl-1 d-flex flex-column">
                <a
                  class="nav-link"
                  href="/admin"
                >
                  <i class="far mr-1 fa-cog text-dark" />
                  Admin panel
                </a>

                <template v-if="adminActions.length">
                  <a
                    v-for="action of adminActions"
                    :key="action.href"
                    class="nav-link"
                    :href="action.href"
                  >
                    <i
                      v-if="action.icon"
                      :class="action.icon"
                    />
                    {{ action.label }}
                  </a>
                </template>
              </div>
            </li>

            <li v-if="partnerLinks.length || isSuperAdmin">
              <hr />

              <div class="font-weight-bolder pt-2 pb-3">Espace de gestion</div>

              <div class="pl-1 d-flex flex-column">
                <a
                  v-if="isSuperAdmin"
                  :href="Routing.generate('partner.list')"
                  style="border-bottom: 1px solid var(--grey)"
                >
                  <i class="fa-duotone fa-buildings" />
                  Tous mes centres
                </a>

                <a
                  v-for="item of partnerLinks"
                  :key="item.id"
                  class="d-flex flex-row"
                  :href="item.url"
                >
                  <img
                    :src="item.background"
                    :alt="item.name"
                    class="rounded-circle mr-3"
                    height="30px"
                    width="30px"
                  />
                  <span class="nav-link">{{ item.name }}</span>
                </a>
              </div>
            </li>

            <li class="pl-1" />

            <li>
              <b-dropdown
                :text="trans('datatable.grouped_actions', 'datatable')"
                variant="transparent btn-lg"
                menu-class="bg-white border w-100"
                size="lg"
                class="my-2"
                toggle-class="pl-0"
              >
                <template #button-content>
                  <i class="far fa-globe" />
                  <span class="">
                    {{ capitalize(trans('general.lang.' + spflocale)) }}
                  </span>
                  <span class="caret" />
                </template>
                <!--    <template #button-content>-->
                <!--      -->
                <!--    </temp/late>-->

                <template #default>
                  <b-dropdown-item
                    v-for="[urlLocale, url] of Object.entries(alternativeUrls)"
                    :id="`header-language_switch_${urlLocale}`"
                    :key="urlLocale"
                    class="dropdown-item"
                    :href="url"
                  >
                    {{ capitalize(trans('general.lang.' + urlLocale)) }}
                  </b-dropdown-item>
                </template>
              </b-dropdown>

              <a
                v-if="isLoggedIn"
                class="nav-link"
                :href="Routing.generate('app_logout')"
              >
                {{ capitalize(trans('website.voc.logout')) }}
              </a>

              <a
                v-if="isPreviousAdmin"
                class="nav-link"
                :href="Routing.generate('user_grid', { _switch_user: '_exit' })"
              >
                Exit impersonation
              </a>
            </li>
          </ul>
        </div>

        <div
          v-if="!isLoggedIn"
          class="p-3 shadow-top position-sticky bg-white border-top"
          style="bottom: 0"
        >
          <!-- USERS NOT CONNECTED -->
          <div class="text-center">
            <b-button
              class="mb-3"
              size="lg"
              block
              variant="orange"
              :href="Routing.generate('app_login', { _locale: spflocale })"
            >
              <b>{{ capitalize(trans('website.top_menu.login')) }}</b>
            </b-button>
            <a
              :href="Routing.generate('app_register')"
              class="text-muted"
            >
              <u>{{ trans('login.register_now') }}</u>
            </a>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
/** global Routing */
import { mapState } from 'vuex';
import SluggableEntityMixin from '../mixins/SluggableEntityMixin';

export default {
  name: 'MainNavbarContent',
  mixins: [SluggableEntityMixin],
  props: {
    center: {
      type: Object,
      required: false,
      default: null,
    },
    centerMatches: {
      type: Number,
      required: false,
      default: null,
    },
    alternativeUrls: {
      type: Object,
      required: true,
    },
    adminActions: {
      type: Array,
      required: true,
    },
    partnerLinks: {
      type: Array,
      required: true,
    },
    activityLinks: {
      type: Array,
      required: true,
    },
    favorites: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    isPreviousAdmin: {
      type: Boolean,
      required: true,
    },
    bookingInvitations: {
      type: Number,
      required: false,
      default: null,
    },
    upcomingBookings: {
      type: Number,
      required: false,
      default: null,
    },
    pendingBookings: {
      type: Number,
      required: false,
      default: null,
    },
    currentCountrySlug: {
      type: String,
      required: false,
      default: 'Belgique',
    },
  },
  emits: ['input:visible'],
  computed: {
    ...mapState('user', ['user']),
    innerIsNavbarOpen: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit('input:visible', v);
      },
    },
    Routing() {
      return Routing;
    },
    isSuperAdmin() {
      return this.$store.state.bossUser;
    },
    isContactHidden() {
      return false;
    },
    hasWarnings() {
      return false;
    },
    currentUrl() {
      return window.location.href;
    },
    slug() {
      return window.SportFinder.store.center?.slug ?? null;
    },
    partnerCenters() {
      return [];
    },
    isLoggedIn() {
      return !!this.$store.state.userId;
    },
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>
