function getSluggableEntity(arr, locale) {
  let d = null;

  if (arr && arr.length === 1) {
    [d] = arr;
  } else if (arr && arr.length > 1) {
    const localizedEntry = arr.find((e) => e.locale === locale);
    return localizedEntry || arr[0];
  }

  return d;
}

export function getSluggableEntityName(arr, locale) {
  const d = getSluggableEntity(arr, locale);

  return d ? d.name : null;
}

export function getSluggableEntitySlug(arr, locale) {
  const d = getSluggableEntity(arr, locale);

  return d ? d.slug : null;
}
