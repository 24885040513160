<template>
  <div
    role="group"
    class="input-group flex-nowrap geocodetype"
    style="position: relative"
  >
    <slot name="prepend" />

    <gmap-autocomplete
      v-if="innerComponentTypes"
      v-bind="$attrs"
      style="height: calc(1.5em + 0.75rem + 2px); outline: 0"
      :options="options"
      :component-restrictions="innerComponentRestrictions"
      :types="innerComponentTypes"
      :value="term"
      :placeholder="placeholder ? trans(placeholder) : null"
      :class="cssClass ?? 'w-100 border-0'"
      @place_changed="handlePlaceChanged"
    />
  </div>
</template>

<script>
export default {
  name: 'GeocodeType',
  props: {
    cssClass: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    initialValue: {
      type: String,
      required: false,
      default: null,
    },
    countryCode: {
      type: String,
      required: true,
    },
    componentRestrictions: { type: Object, required: false, default: null },
    componentTypes: { type: Array, required: false, default: null },
  },
  emits: ['place_changed'],
  data() {
    return {
      options: {
        offset: 3,
        fields: ['name', 'place_id', 'geometry', 'types', 'address_components'],
      },
    };
  },
  computed: {
    term() {
      return this.initialValue;
    },
    innerComponentRestrictions() {
      return (
        this.componentRestrictions ?? {
          country: [this.countryCode],
        }
      );
    },
    innerComponentTypes() {
      return this.componentTypes ?? ['(regions)'];
    },
  },
  methods: {
    handlePlaceChanged(e) {
      // console.dir(e);
      this.$emit('place_changed', e);
    },
  },
};
</script>
