<template>
  <div>
    <a
      v-if="!isMobile || (isAndroid && isInstallable && !isInstalled)"
      class="d-inline-block"
      href="https://play.google.com/store/apps/details?id=com.sportfinder.twa&utm_source=website&utm_campaign=banner-footer&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
    >
      <img
        alt="Get it on Google Play"
        :src="gplayImgSource"
        style="width: 140px; max-width: 100%"
      />
    </a>
    <!-- <a
      v-if="!isMobile || (isIOS && isInstallable && !isInstalled)"
      href="https://apps.apple.com/us/app/playtomic/id1242321076?itsct=apps_box_badge&amp;itscg=30200"
      style="width: 200px; max-width: 100%;"
      class="d-inline-block px-2"
    >
      <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1509580800"
        alt="Download on the App Store"
        style="width: 170px; max-width: 100%;"
      >
    </a> -->
    <b-button
      v-if="!isAndroid && !isIOS && isInstallable && !isInstalled"
      :variant="variant"
      size="small"
      @click="handleInstall"
    >
      <slot>{{ trans('pwa.footerCtaAction') }}</slot>
    </b-button>
  </div>
</template>

<script>
import PwaAwareMixin from '../mixins/PwaAwareMixin.vue';
import detectBrowser from '../../helpers/browserDetection';

export default {
  name: 'InstallButton',
  mixins: [PwaAwareMixin],
  props: {
    variant: { type: String, required: false, default: 'primary' },
  },
  computed: {
    isAndroid() {
      const { isAndroid } = detectBrowser();
      return isAndroid;
    },
    isIOS() {
      const { isIOS } = detectBrowser();
      return isIOS;
    },
    isMobile() {
      const { isMobile } = detectBrowser();
      return isMobile;
    },
    gplayImgSource() {
      return `https://play.google.com/intl/en_us/badges/static/images/badges/${this.spflocale}_badge_web_generic.png`;
    },
  },
};
</script>
