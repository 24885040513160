import { sendGetRequest, objectToQueryString } from './Hydra';
import apiRoutes from '../../router/api';

export async function findZone(options) {
  const selectedOptions = {};
  Object.keys(options)
    .filter((k) => options[k])
    .forEach((k) => {
      selectedOptions[k] = options[k];
    });

  const uri = new URL(apiRoutes.zone.search, window.location.origin);
  uri.search = objectToQueryString(options, '');

  const response = await sendGetRequest(uri);
  // console.dir(response);
  return response['hydra:member'];
}
