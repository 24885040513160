<script>
import detectBrowser from '../../helpers/browserDetection';
import { appInstalled } from '../../services/ga';

export default {
  data() {
    return {
      promptEvent: null,
      isInstalled: false,
      isInstallable: false,
    };
  },
  created() {
    // if this thing is available, we may rely on beforeinstallprompt event
    if (window.BeforeInstallPromptEvent) {
      // console.log('had beforeinstallprompt')
      // If the thing is not installed we'll have the event
      this.isInstalled = true;
      this.isInstallable = true;
      window.addEventListener('beforeinstallprompt', this.handleBeforeInstalledPrompt);
    }

    // Are we in a compatible context ?
    // Safari iOs, firefox and qq compatible without event
    const { isIOS, isFirefoxMobile, isAndroid } = detectBrowser();
    if (isAndroid || isFirefoxMobile || isIOS) {
      this.isInstallable = true;
    }

    window.addEventListener('appinstalled', this.handleAppInstalled);
  },
  methods: {
    redirectToHelp() {
      window.location.pathname = Routing.generate('help');
    },
    notifyAnalytics() {
      appInstalled();
    },
    async handleInstall() {
      if (!this.promptEvent) {
        return this.redirectToHelp();
      }

      try {
        const response = await this.promptEvent?.prompt();
        if (response.outcome === 'accepted') {
          this.isInstalled = true;
          this.notifyAnalytics();
        }
        console.debug(response);
      } catch (e) {
        console.dir(e);
      }
      return null;
    },
    handleBeforeInstalledPrompt(e) {
      // console.debug(e)
      this.isInstalled = false;
      this.promptEvent = e;
    },
    handleAppInstalled() {
      // console.debug(evt)
      this.isInstalled = true;
      this.promptEvent = null;
    },
  },
};
</script>
