<template>
  <div class="my-2">
    <install-button
      variant="primary is-light"
      style="vertical-align: middle; font-weight: 300"
    >
      {{ trans('pwa.accountCtaAction') }}
    </install-button>
  </div>
</template>

<script>
import PwaAwareMixin from '../mixins/PwaAwareMixin.vue';
import InstallButton from './InstallButton.vue';

export default {
  name: 'InstallBanner',
  components: { InstallButton },
  mixins: [PwaAwareMixin],
  props: {
    variant: { type: String, required: false, default: 'primary' },
    closable: { type: Boolean, required: false, default: false },
  },
};
</script>
