<template>
  <div>
    <navbar-content
      :center="center"
      :center-matches="centerMatches"
      :favorites="favorites"
      :alternative-urls="alternativeUrls"
      :visible.sync="isNavbarOpen"
      :is-admin="isAdmin"
      :is-previous-admin="isPreviousAdmin"
      :admin-actions="adminActions"
      :partner-links="partnerLinks"
      :activity-links="activityLinks"
      :current-country-slug="currentCountrySlug"
    />

    <button
      v-b-toggle.sidebar-nav
      class="navbar-toggler mr-2 text-white"
      type="button"
    >
      <span class="fa fa-navicon fa-lg" />
    </button>
  </div>
</template>

<script>
/** global Routing */

import { mapState } from 'vuex';
import NavbarContent from './NavbarContent.vue';

export default {
  name: 'MainNavbar',
  components: { NavbarContent },
  props: {
    center: {
      type: Object,
      required: false,
      default: null,
    },
    centerMatches: {
      type: Number,
      required: false,
      default: undefined,
    },
    alternativeUrls: {
      type: Object,
      required: true,
    },
    currentCountrySlug: {
      type: String,
      required: false,
      default: 'Belgique',
    },
    favorites: { type: Array, required: true },
    adminActions: { type: Array, required: true },
    partnerLinks: { type: Array, required: true },
    activityLinks: { type: Array, required: true },
    isAdmin: { type: Boolean, required: true },
    isPreviousAdmin: { type: Boolean, required: true },
    // bookingInvitations: {
    //   type: Number,
    //   required: false,
    //   default: null,
    // },
    // upcomingBookings: {
    //   type: Number,
    //   required: false,
    //   default: null,
    // },
    // pendingBookings: {
    //   type: Number,
    //   required: false,
    //   default: null,
    // },
  },
  data() {
    return { isNavbarOpen: false };
  },
  computed: {
    ...mapState('user', ['user']),
    Routing() {
      return Routing;
    },
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>
